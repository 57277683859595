<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE NAME -->
            <div class="filter">
                <label>{{ $t('user.user_name') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE SELECT SITE OR TERRITORIES -->
            <div class="filter">
                <label>{{ $t('user.filters.search_by') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.sites,
                        changed: JSON.stringify(currentFilters.sites) != JSON.stringify(activeFilters.sites)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.sites"
                    v-if="Object.values(optionsSites)"
                    :items="Object.values(optionsSites)"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    @change="refreshSearch()"
                ></v-select>

                <div class="locations" v-if="currentFilters.sites == 'locations'">
                    <label class="description">{{ $t('user.filters.available_sites') }}</label>
                    <v-autocomplete
                        :class="{
                            filtered:
                                currentFilters.locations &&
                                typeof currentFilters.locations !== 'undefined' &&
                                Object.values(currentFilters.locations).length > 0,
                            changed: JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations)
                        }"
                        hide-details
                        class="select"
                        v-model="currentFilters.locations"
                        v-if="Object.values(optionsLocations)"
                        :items="Object.values(optionsLocations)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                        return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>
                    <Tags :tags="currentFilters.locations" :backImage="'location_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
                </div>
                <div class="custom" v-else-if="currentFilters.sites == 'groups'">
                    <label class="description">{{ $t('user.filters.available_territories') }}</label>
                    <v-autocomplete
                        :class="{
                            filtered: currentFilters.groups && typeof currentFilters.groups !== 'undefined' && Object.values(currentFilters.groups).length > 0,
                            changed: JSON.stringify(currentFilters.groups) != JSON.stringify(activeFilters.groups)
                        }"
                        hide-details
                        class="select"
                        v-model="currentFilters.groups"
                        v-if="Object.values(optionsGroups)"
                        :items="Object.values(optionsGroups)"
                        item-text="name"
                        item-value="id"
                        multiple
                        attach
                        dense
                        :no-data-text="$t('supervise.filter.no_results')"
                        :placeholder="$t('assets.filter.filter_by')"
                        return-object
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ index == 0 ? '' : '' }}
                        </template>
                    </v-autocomplete>
                    <Tags :tags="currentFilters.groups" :backImage="'menu_inactive_s30.svg'" :nameTag="'name'" value="id" :removable="true"></Tags>
                </div>
            </div>

            <!-- Status -->
            <div class="filter">
                <label>{{ $t('supervise.issues.table_headers.state') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.state && Object.values(currentFilters.state).length > 0,
                        changed: JSON.stringify(currentFilters.state) != JSON.stringify(activeFilters.state)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.state"
                    :items="Object.values(optionsStatus)"
                    item-text="name"
                    item-value="id"
                    multiple
                    attach
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0">
                            <span>{{ item.name }}</span>
                        </span>
                        <div v-if="index === 1" class="grey--text caption">(+{{ currentFilters.state.length - 1 }})</div>
                    </template>
                </v-autocomplete>
            </div>
            <!-- TYPE Roles -->
            <div class="filter">
                <label>{{ $t('user.role') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.roles && typeof currentFilters.roles !== 'undefined' && Object.values(currentFilters.roles).length > 0,
                        changed: JSON.stringify(currentFilters.roles) != JSON.stringify(activeFilters.roles)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.roles"
                    :items="Object.values(optionsRoles.data)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.roles" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
            <!-- TYPE TAGS -->
            <div class="filter">
                <label> {{ $t('template.general.tags.title') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.tags && typeof currentFilters.tags !== 'undefined' && Object.values(currentFilters.tags).length > 0,
                        changed: JSON.stringify(currentFilters.tags) != JSON.stringify(activeFilters.tags)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.tags"
                    :items="Object.values(optionsTags.data)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.tags" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <FilterActions
            :showClearFiltersButton="showClearFiltersButton"
            :clearFiltersCallback="resetFilters"
            :filterCallback="applyFilters"
            :changesDetected="changesDetected"
        />
    </div>
</template>

<script>
import i18n from '@/i18n'
import { TYPE } from '@/constants'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterIssue',
    components: { FilterActions },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    data() {
        return {
            type: TYPE.ISSUE,
            currentFilters: {},
            emptyFilters: {},
            activeFilters: {},
            // Filter variables
            changesDetected: false
        }
    },
    computed: {
        optionsStatus() {
            return this.$store.getters['template/getFilterStatus'](this.type)
        },
        optionsGroups() {
            return this.$store.getters['template/getFilterGroups'](this.type)
        },
        optionsLocations() {
            return this.$store.getters['template/getFilterLocations'](this.type)
        },
        optionsSites() {
            return [
                { value: 'allAvaibles', name: i18n.t('user.filters.all_avaibles') },
                { value: 'locations', name: i18n.t('user.sites') },
                { value: 'groups', name: i18n.t('user.filters.territories') },
            ]
        },
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        },
        optionsRoles() {
            return this.$store.getters['template/getFilterRoles']()
        },
        optionsTags() {
            return this.$store.getters['template/getFilterTags']()
        }
    },
    methods: {
        refreshSearch() {
            delete this.currentFilters.locations
            delete this.currentFilters.groups
        },
        applyFilters() {
            this.$overlay.loading()

            this.$emit('newFiltersApplied')

            var definitiveFilters = {}
            var locations = []
            var groups = []

            // name
            if (this.currentFilters.name) {
                definitiveFilters.name = this.currentFilters.name
            } else definitiveFilters.name = ''

            if (this.currentFilters.groups) {
                var filter = this.currentFilters.groups
                for (var index in filter) {
                    groups.push(filter[index].id)
                }
                definitiveFilters.groups = groups
            } else {
                definitiveFilters.groups = ''
            }
            if (this.currentFilters.locations) {
                var filter = this.currentFilters.locations
                for (var index in filter) {
                    locations.push(filter[index].id)
                }
                definitiveFilters.locations = locations
            } else {
                definitiveFilters.locations = ''
            }

            if (this.currentFilters.sites) {
                this.currentFilters.sites === 'allAvaibles' ? definitiveFilters.allAvaibles = 1 : definitiveFilters.allAvaibles = null
            }

            // status
            if (this.currentFilters.state && Object.values(this.currentFilters.state).length) {
                var filter = this.currentFilters.state
                definitiveFilters.state = []
                for (var index in filter) {
                    definitiveFilters.state.push(filter[index].id)
                }
                definitiveFilters.state = definitiveFilters.state.join(',')
            } else definitiveFilters.state = ''

            if (this.currentFilters.roles && Object.values(this.currentFilters.roles).length) {
                var filter = this.currentFilters.roles
                definitiveFilters.roles = []
                for (var index in filter) {
                    definitiveFilters.roles.push(filter[index].id)
                }
                definitiveFilters.roles = JSON.stringify(definitiveFilters.roles)
            } else definitiveFilters.roles = ''

            if (this.currentFilters.tags && Object.values(this.currentFilters.tags).length) {
                var filter = this.currentFilters.tags
                definitiveFilters.tags = []
                for (var index in filter) {
                    definitiveFilters.tags.push(filter[index].id)
                }
                definitiveFilters.tags = JSON.stringify(definitiveFilters.tags)
            } else definitiveFilters.tags = ''

            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }

            var self = this
            this.changesDetected = false

            const filters = {
                name: definitiveFilters.name,
                status: definitiveFilters.state,
                locations: definitiveFilters.locations,
                groups: definitiveFilters.groups,
                roles: definitiveFilters.roles,
                tags: definitiveFilters.tags,
                allAvaibles: definitiveFilters.allAvaibles,
            }

            self.$store.commit('template/setTemplatesToExportFilters', filters)

            this.$store.commit('template/setFiltersActive', { type: self.type, data: this.currentFilters })
            this.$store
                .dispatch('template/loadTemplates', {
                    type: self.type,
                    data: filters
                })
                .then(function () {
                    self.activeFilters = { ...self.currentFilters }
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        },
        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            this.$store.commit('template/setFiltersActive', { type: self.type, data: this.currentFilters })

            // reset filters for export templates:
            const defaultFilters = { name: '', status: '', locations: '', groups: '', roles: '' }
            self.$store.commit('template/setTemplatesToExportFilters', defaultFilters)

            this.changesDetected = false
            this.$store.dispatch('template/loadTemplates', { type: self.type, data: {} }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        }
    },
    created() {},
    mounted() {
        this.activeFilters = { ...this.$store.getters['template/getFiltersActive'](this.type) }
        this.currentFilters = { ...this.$store.getters['template/getFiltersActive'](this.type) }
        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                    this.changesDetected = true
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
